import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/things-to-avoid-when-using-react-hooks",
  "date": "2021-10-31",
  "title": "Things to avoid when using React Hooks",
  "author": "admin",
  "tags": ["development", "javascript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we discuss about a few things to avoid when writing React Hooks and forming good habits through understanding"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Not using the ESLint plugin`}</h2>
    <p>{`Why not avoid the bugs related to hooks by following the rules suggested by installing the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/eslint-plugin-react-hooks"
      }}>{`eslint-plugin-react-hooks`}</a>{`? This can easily help you enforce the correct hooks execution order.`}</p>
    <p>{`The default recommended configuration of these rules is to set "rules of hooks" to an error, and the "exhaustive deps" to a warning.`}</p>
    <h2>{`Changing hooks invocation order`}</h2>
    <p>{`The way React hooks internally work requires components to invoke hooks in the same order between renderings — always!`}</p>
    <p>{`That’s exactly what suggests the first rule of hooks: Don’t call Hooks inside loops, conditions, or nested functions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level"
      }}>{`Rules of Hooks — React`}</a></p>
    <p>{`Solving the incorrect order of hooks means moving the return statement after invoking the hooks:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useEffect, useState } from 'react';

function Character({ id }) {
  /** Move this! */
  // if (!id) {
  //  return "Please select a game to fetch";
  // }
  const [character, setCharacter] = useState({
    name: '',
    description: '',
  });

  const fetchCharacter = async () => {
    const response = await fetch(\`https://swapi.dev/api/people/\${id}\`);
    const data = await response.json();
    console.log('here');
    setCharacter(data);
  };

  useEffect(() => {
    if (id) {
      fetchCharacter();
    }
  }, [id]);
  /** to here */
  if (!id) {
    return 'Please select a game to fetch';
  }

  return (
    <main>
      <h2>{character.name}</h2>
      <div>Name: {character.birth_year}</div>
      <div>Height: {character.height}</div>
    </main>
  );
}

export default Character;
`}</code></pre>
    <p>{`Now, no matter id is empty or not, the `}<em parentName="p">{`useState()`}</em>{` and `}<em parentName="p">{`useEffect()`}</em>{` hooks are always invoked in the same order. So make sure that you don't change the order of the hooks invocation.`}</p>
    <h2>{`Thinking in Lifecycles`}</h2>
    <p>{`Before functional components becamae a thing, we had a nice and clear component API that made it easy for us to tell React when it should do certain things:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`class MyComponent extends React.Component {
  constructor() {
    // initialize component instance
  }
  componentDidMount() {
    // The component is first added to the page
  }
  componentDidUpdate(prevProps, prevState) {
    // The component is updated on the page
  }
  componentWillUnmount() {
    // The component is removed from the page
  }

  render() {
    // render React elements
  }
}
`}</code></pre>
    <p>{`This declarative component made us think in lifecycles. But with React hooks, we should think about synchronizing the state of the side-effects with the state of the application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { useEffect } from 'react';

function MyComponent() {
  useEffect(() => {
    // Run once after DOMContentEvent loads
  }, []);

  useEffect(() => {
    // Synchronize the state with the state of this component.
    return function cleanup() {
      // Cleanup the previous side-effect before running a new one
    };
    // Run the side-effect and it's cleanup to for the re-run
  }, [a, b, c, d]); // When any of these change

  useEffect(() => {
    // Run every single time this component is re-rendered
  });

  return {
    // Render React elements
  };
}
`}</code></pre>
    <h2>{`Using stale state`}</h2>
    <p>{`For example, trying to increase the state variable count when a button is clicked:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { useCallback } from 'react';

function Count() {
  const [count, setCount] = useState(0);

  const increase = useCallback(() => {
    /** Avoid */
    // setCount(count + 1);
    /** Use a callback instead */
    setCount(count => count + 1);
  }, [count]);

  const handleClick = () {
    increase();
    increase();
    increase();
  };

  return (
    <>
      <button onClick={handleClick}>+</button>
      <div>Counter: {count}</div>
    </>
  );
}
`}</code></pre>
    <p>{`Even though increase() is called 3 times inside the handleClick(), each call will result in a `}<em parentName="p">{`stale state`}</em>{`.`}</p>
    <p>{`By using an updater `}<inlineCode parentName="p">{`function count => count + 1`}</inlineCode>{`, React gives you the latest actual state value.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you use the current state to calculate the next state, always use a functional way to update the state: setValue(prevValue => prevValue + someResult)`}</p>
    </blockquote>
    <h2>{`Overthinking performance`}</h2>
    <p>{`Let's say we define an event handler for a child component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function ParentComponent() {
  function handleClick() {
    console.log('clicked inside child component');
  }

  return <ChildComponent onClick={handleClick} />;
}
`}</code></pre>
    <p>{`There are 2 reasons people worry about this:`}</p>
    <ol>
      <li parentName="ol">{`We're defining the function inside the component, meaning it's getting re-defined every single time `}<strong parentName="li"><inlineCode parentName="strong">{`<ParentComponen/>`}</inlineCode></strong>{` is rendered`}</li>
      <li parentName="ol">{`We're passing handler function as a prop to `}<strong parentName="li"><inlineCode parentName="strong">{`<ChildComponent />`}</inlineCode></strong>{` which means it can't be optimized properly with `}<strong parentName="li"><inlineCode parentName="strong">{`React.memo`}</inlineCode></strong>{` and will suffer from "unnecessary re-renders"`}</li>
    </ol>
    <p>{`But this is not quite true:`}</p>
    <ol>
      <li parentName="ol">{`Redefining too many functions - JavaScript engiens are fast enough for defining or redefining native functions.`}</li>
      <li parentName="ol">{`Just because a component re-renders, does not mean the DOM will get updated every time.`}</li>
    </ol>
    <h2>{`Creating Stale Closures`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures"
      }}>{`closure`}</a>{` is the JavaScript lexical scope that a function uses to wrap its varibles within. React hooks rely on this closure for them to function properly.`}</p>
    <p>{`Let's look at the example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { useState } from 'react';

function App() {
  const [count, setCount] = useState(0);

  function handleClick() {
    return setTimeout(() => {
      alert('You clicked on: ' + count);
    }, 3000);
  }

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>Click me</button>
      <button onClick={handleClick}>Show alert</button>
    </div>
  );
}
`}</code></pre>
    <p>{`Whenever the `}<inlineCode parentName="p">{`setCount`}</inlineCode>{` is called the state gets a new reference. This means the original state doesn't have the new value, but a new state with the new value. When we click on the second button, the event handler captures the reference of the original state.`}</p>
    <p>{`Even if we click the first button as many times as we like when the alert is displayed it will only show the old value.`}</p>
    <p>{`To fix this stale-closure issue, will use a ref:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function App() {
  const [count, setCount] = useState(0);

  const latestValue = useRef(count);

  const handleClick = () => {
    setTimeout(() => {
      alert(\`count is: \${latestValue.current}\`);
    }, 3000);
  };

  return (
    <div>
      <p>You clicked {count} times</p>
      <button
        onClick={() => {
          setCount((prev) => {
            latestValue.current = prev + 1;
            return prev + 1;
          });
        }}
      >
        Click me
      </button>
      <button onClick={handleClick}>Show alert</button>
    </div>
  );
}
`}</code></pre>
    <h2>{`Overthinking the testing of hooks`}</h2>
    <p>{`Writing test for hooks in a way that tests all of their components when they refactor to hooks is unnecessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`test('Clicking on + increments the number', () => {
  // using enzyme
  const wrapper = mount(<Count />);

  expect(wrapper.state('count')).toBe(0);
  wrapper.instance().incrementCount();
  expect(wrapper.state('count')).toBe(1);
});
`}</code></pre>
    <p>{`Writing your tests to interact with what's being rendered, then it doesn't matter how that stuff gets rendered to the screen.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`test('Clicking on + increments the number', () => {
  // using React Testing Library
  render(<Count />);

  expect(screen.getByText('Total')).toBeInTheDocument();
  userEvent.click(screen.getByText('Click'));
  expect(screen.queryByText('1')).toBeInTheDocument();
});
`}</code></pre>
    <h2>{`Not cleaning up side effects`}</h2>
    <p>{`Side effects like API calls or browser API events like setTimeout or setInterval are asynchronous calls. If you don't clean up these, React will warn you every time on the console about a component is unmounted and you are trying to perform a state update on it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`useEffect(() => {
  if (increase) {
    const id = setInterval(() => {
      setCount((count) => count + 1);
    }, 1000);
    return () => clearInterval(id);
  }
}, [increase]);
`}</code></pre>
    <h2>{`Summary`}</h2>
    <ol>
      <li parentName="ol">{`Make sure, you use the ESLint plugin for hooks`}</li>
      <li parentName="ol">{`Avoid changing hooks invocation order`}</li>
      <li parentName="ol">{`Stop thinking in Lifecycles`}</li>
      <li parentName="ol">{`Avoid using stale state`}</li>
      <li parentName="ol">{`Stop overthinking performance`}</li>
      <li parentName="ol">{`Avoid creating Stale Closures`}</li>
      <li parentName="ol">{`Don't overthink writing tests for testing hooks`}</li>
      <li parentName="ol">{`Clean up side effects`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      